import React from 'react';
import classes from './Dashboard.module.css';
import Queue from '../../components/common/Queue/Queue';
import Navbar from '../../components/common/Navbar/Navbar';
import Search from '../../components/common/SearchBar/Search';
import { useEffect } from 'react';
import {
  selectItems,
  pushTrack,
  selectPosition,
  setPosition,
} from '../../app/queueSlice';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { selectDashboard, setWindowWidth } from '../../app/dashboardSlice';
import PlayerController from '../../components/common/PlayerController';
import Switch from '../../components/ui/Switch';
import YoutubeEmbed from '../../components/common/YoutubeEmbed';
import { checkBotStatus } from '../../app/useSocket';
import { selectError, setError } from '../../app/authSlice';
import { useHistory } from 'react-router';

export enum breakpoints {
  LARGE = 1150,
  MEDIUM = 800,
  SMALL = 0,
}

const Dashboard: React.FC = () => {
  const dispatch = useAppDispatch();
  const items = useAppSelector(selectItems);
  const position = useAppSelector(selectPosition);
  const { windowWidth } = useAppSelector(selectDashboard);
  const history = useHistory();
  const error = useAppSelector(selectError);

  useEffect(() => {
    init();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  async function init() {
    if (!(await checkBotStatus())) {
      dispatch(
        setError({
          code: 503,
          message: 'Bot is offline or not responding.',
          redirect_path: '/offline',
        })
      );
    }
  }

  function handleResize(event: UIEvent) {
    const win = event.target as Window;
    if (event.target && win.innerWidth !== windowWidth)
      dispatch(setWindowWidth(win.innerWidth));
  }

  useEffect(() => {
    if (error) {
      history.push(error.redirect_path ?? '/login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <div className={classes.dashboard_container}>
      <Navbar />
      <div className={classes.dashboard__body}>
        <section id={classes.header}>
          <Search requestTrack={(track) => dispatch(pushTrack(track))} />
          <Switch />
        </section>
        <section id={classes.video} className={classes.dashboard__component}>
          <YoutubeEmbed embedId={items.length ? items[position].id : ''} />
        </section>
        <section id={classes.queue} className={classes.dashboard__component}>
          <Queue
            items={items}
            setTrack={(pos: number) => dispatch(setPosition(pos))}
          />
        </section>
        <section
          id={classes.player_controls}
          className={classes.dashboard__component}
        >
          <PlayerController />
        </section>
      </div>
    </div>
  );
};

export default Dashboard;
