import React, { useEffect, useRef } from 'react';
import classes from './Navbar.module.css';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { ReactComponent as Logo } from '../../../assets/Logo.svg';
import { ReactComponent as MenuIcon } from '../../../assets/menu.svg';
import {
  selectDashboard,
  setNavbarVisibility,
} from '../../../app/dashboardSlice';

const Navbar: React.FC = () => {
  const dispatch = useAppDispatch();
  const { windowWidth, navbar: isVisible } = useAppSelector(selectDashboard);
  const navbarRef = useRef<HTMLDivElement>(null);

  // When navbar becomes visible, add event listener to
  // check for clicks outside navbar and dismiss it.
  useEffect(() => {
    if (windowWidth > 1150) return;
    if (isVisible) {
      window.addEventListener('click', handleWindowClick);
    } else {
      window.removeEventListener('click', handleWindowClick);
    }
    return () => {
      window.removeEventListener('click', handleWindowClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, windowWidth]);

  function handleWindowClick(event: MouseEvent) {
    if (!navbarRef.current || !isVisible) return;
    const { clientX: clickX, clientY: clickY } = event;
    const { width } = navbarRef.current.getBoundingClientRect();
    if (clickX > width) {
      dispatch(setNavbarVisibility(false));
    }
  }

  return (
    <>
      {windowWidth < 1150 && (
        <div
          className={classes.menu_btn}
          onClick={() => dispatch(setNavbarVisibility(true))}
        >
          <MenuIcon />
        </div>
      )}
      <div
        ref={navbarRef}
        className={`${classes.navbar_container} ${
          isVisible ? classes.active : classes.hidden
        }`}
      >
        <div className={classes.logo_container}>
          <Logo />
        </div>
        <section className={classes.guilds_container}>{/* TODO */}</section>
        <section className={classes.navbar__footer}>
          <div className={classes.navLink}>Help</div>
        </section>
      </div>
    </>
  );
};

export default Navbar;
